<template>
  <b-overlay>
    <b-card>
      <b-button
          v-if="$checkPermission($permissions.PermissionCreateCuttingPlan) &&  (planType === 'all' || planType === 'odprt')"
          variant="primary" @click="addPlanActive = true">Dodaj plan
      </b-button>
      <b-button class="ml-1" variant="success" href="/api/v1/user/plan/open_excel">Izvozi Excel odprtih planov
      </b-button>
      <b-button class="ml-1" variant="success" href="/api/v1/user/plan/closed_excel">Izvozi Excel potrjenih planov
      </b-button>
      <b-button class="ml-1" variant="success" href="/api/v1/user/plan/all_excel">Izvozi Excel vseh planov
      </b-button>
      <b-button v-if="filteredByOrder === false" class="ml-1" variant="primary" @click="showOrderSearchModal = true" >Išči po naročilu
      </b-button>
      <b-button v-if="filteredByP === false" class="ml-1" variant="primary" @click="showPSearchModal = true" >Išči po P-številki
      </b-button>
      <b-button v-if="filteredByOrder || filteredByP" @click="filteredByOrder = false; filteredByP = false; getPlans()" class="ml-1">Prikaži vse</b-button>

      <div>
        <b-form-input type="search" v-model="search" placeholder="Išči..." size="sm" class="float-right col-sm-2 mb-1"/>
        <b-table striped responsive="sm" bordered hover :items="filtered" :fields="fields" :filter="search"
                 :per-page="perPage" :current-page="currentPage">
          <template slot="top-row" slot-scope="{ fields }">
            <b-td v-for="field in fields" :key="field.key">
              <b-form-input v-if="field.key !== 'actions'" size="sm" v-model="filters[field.key]"
                            :placeholder="field.label"/>
            </b-td>
          </template>
          <template v-slot:cell(status)="row">
            <b-badge v-if="row.item.status === 'potrjen'" variant="success">{{ row.item.status }}</b-badge>
            <b-badge v-else-if="row.item.status === 'odprt'" variant="warning">{{ row.item.status }}</b-badge>
            <b-badge v-else variant="danger">{{ row.item.status }}</b-badge>
          </template>
          <template v-slot:cell(order_number)="row">
            {{ row.item.orders.length }}
          </template>
          <template v-slot:cell(date_planned)="row">
            {{ new Date(row.item.date_planned).toLocaleDateString('sl') }}
          </template>
          <template v-slot:cell(filled_amount)="row">
            {{ (row.item.orders.filter(order => order.order_pieces !== undefined).reduce((acc, cur) => acc + cur.order_pieces.reduce((innerAcc, innerCur) => innerAcc + (innerCur.quantity * innerCur.length * innerCur.width), 0), 0) / 1000000).toFixed(2) }}m²
          </template>
          <template #cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-button v-if="$checkPermission($permissions.PermissionUpdateCuttingPlan)" class="mr-1"
                        @click="$router.push({path: `/plan/${row.item.id}`})" variant="success">
                <fa icon="eye"/>
              </b-button>
              <b-button v-if="$checkPermission($permissions.PermissionDeleteCuttingPlan)"
                        @click="removePlan(row.item.id)" variant="danger">
                <fa icon="trash"/>
              </b-button>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col class="float-left">
            <b-dropdown variant="outline" :text="'Na stran: ' + perPage" size="sm" class="btn-none">
              <b-dropdown-item v-model="perPage" v-for="(item, key) in pageOptions" :key="key"
                               @click="setPerPage(item)">
                {{ item }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col sm="7" md="6" class="float-right">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right" size="sm"/>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal title="Dodaj plan" v-model="addPlanActive" centered hide-footer>
      <b-datepicker v-model="date" :date-format-options="dateFormat" value-as-date start-weekday="1" label-help=""
                    label-no-date-selected="Izberi datum"/>
      <b-form-group label="Planirani m² ">
        <b-form-input v-model="config.daily_cut_capacity" type="number"/>
      </b-form-group>
      <b-button class="float-right mt-1" @click="addPlan" variant="primary">Dodaj</b-button>
    </b-modal>

    <b-modal title="Išči po naročilu" v-model="showOrderSearchModal" centered hide-footer>
      <b-form-input v-model="searchOrder" placeholder="ID naročila v obliki 23PN004228-10000"/>
      <b-button class="float-right mt-1" @click="searchByOrder" variant="primary">Išči</b-button>
    </b-modal>

    <b-modal title="Išči po P-številki" v-model="showPSearchModal" centered hide-footer>
      <b-form-input v-model="searchP" placeholder="P-števila v obliki 1234"/>
      <b-button class="float-right mt-1" @click="searchByP" variant="primary">Išči</b-button>
    </b-modal>
  </b-overlay>
</template>

<script>
    export default {
        props: {
            planType: {
                type: String,
                required: false,
                default: 'all'
            }
        },
        data() {
            return {
                date: new Date(new Date().toDateString()),
                dateFormat: {'year': 'numeric', 'month': 'numeric', 'day': 'numeric'},
                plans: [],
                search: '',
                perPage: 10,
                searchOrder: '',
                filteredByOrder: false,
                showOrderSearchModal: false,
                showPSearchModal: false,
                filteredByP: false,
                searchP: '',
                pageOptions: [10, 20, 50, 100],
                currentPage: 1,
                config: {daily_cut_capacity: 500},
                selected: {},
                fields: [
                    {key: 'date_planned', label: 'Datum', sortable: true, class: 'text-center'},
                    {key: 'bcid', label: 'Šifra', class: 'text-center'},
                    {key: 'filled_amount', label: 'Kvadratura', class: 'text-center'},
                    {key: 'order_number', label: 'Št. naročil', class: 'text-center'},
                    {key: 'status', label: 'Status', sortable: true, class: 'text-center'},
                    {key: 'actions', label: '', class: 'text-center'}
                ],
                filters: {date_planned: '', status: ''},
                addPlanActive: false
            }
        },
        methods: {
            getPlans() {
                const thisIns = this
                this.$http.get('/api/v1/user/plan/')
                    .then(function(response) {
                        thisIns.plans = response.data
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            },
            setPerPage(item) {
                this.perPage = item
            },
            searchByOrder() {
                const thisIns = this
                this.$http.get(`/api/v1/user/plan/order/${this.searchOrder}`)
                    .then(function(response) {
                        thisIns.plans = response.data
                        thisIns.showOrderSearchModal = false
                        thisIns.filteredByOrder = true
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            },
            searchByP() {
                const thisIns = this
                this.$http.get(`/api/v1/user/plan/p/${this.searchP}`)
                    .then(function(response) {
                        thisIns.plans = response.data
                        thisIns.showPSearchModal = false
                        thisIns.filteredByP = true
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            },
            addPlan() {
                const thisIns = this
                this.$http.put('/api/v1/user/plan/', {
                    status: 'odprt',
                    date_planned: this.date.toISOString(),
                    planned_amount: Number(this.config.daily_cut_capacity)
                })
                    .then(function() {
                        thisIns.getPlans()
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
                this.addPlanActive = false
                this.getPlans()
            },
            removePlan(itemId) {
                const thisIns = this
                this.$http.delete(`/api/v1/user/plan/${itemId}`)
                    .then(function() {
                        thisIns.$printSuccess('Plan uspešno odstranjen')
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
                this.plans = this.plans.filter(function(item) {
                    return item.id !== itemId
                })
                this.getPlans()
            },
            getConfig() {
                const thisIns = this
                this.$http.get('/api/v1/user/common_config/')
                    .then(function(response) {
                        thisIns.config = response.data
                    }).catch(function(error) {
                        thisIns.$printWarning(`Napaka! <br>Koda: ${error.response.status}`)
                    })
            },
            flatten(obj, prefix = [], current = {}) {
                if (typeof (obj) === 'object' && obj !== null) {
                    for (const key of Object.keys(obj)) {
                        this.flatten(obj[key], prefix.concat(key), current)
                    }
                } else {
                    current[prefix.join('.')] = obj
                }
                return current
            }
        },
        computed: {
            filtered() {
                return this.plans.filter(plan => this.planType === 'all' || plan.status === this.planType).filter(plan => {
                    return Object.keys(this.filters).every(key => String(plan[key]).toLowerCase().includes(this.filters[key].toLowerCase()))
                })
            },
            totalRows() {
                return this.filtered.length
            }
        },
        mounted() {
            this.getPlans()
            this.getConfig()
        }
    }
</script>
